import { Button, CardActionArea, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useState } from "react";
import { ArrowRight, Close, Download, Info, Logout, Payment, Visibility, VisibilityOff } from "@mui/icons-material";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import SubscriptionLottieAnimation from "../animations/subscription-lottie-animation.json";
import AllImagesDialog from "./all-images-dialog";
import { auth } from "../firebase";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

export default function ImageViewerDialog(props) {
    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const [showSettings, setShowSettings] = useState(false);
    const [useImageTypeDialogOpen, setUseImageTypeDialogOpen] = useState(false);
    const [useImagePaymentTypeDialogOpen, setUseImagePaymentTypeDialogOpen] = useState(false);
    const [addImagesDialogOpen, setAddImagesDialogOpen] = useState(false);
    const [makeGlobalLoading, setMakeGlobalLoading] = useState(false);
    const [onlyForThisPageLoading, setOnlyForThisPageLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [user, setUser] = useState(null);
    const [signoutConfirmationDialogOpen, setSignoutConfirmationDialogOpen] = useState(false);

    const handleUseImage = () => {
        setUseImageTypeDialogOpen(false);
        setUseImagePaymentTypeDialogOpen(true);
    };

    const handleSignInWithPopup = () => {
        if (!auth.currentUser) {
            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider)
                .then((result) => {
                    const user = result.user;
                    setUser(user);
                    user.getIdToken().then((idToken) => {
                        handleUseImage();
                        setMakeGlobalLoading(false);
                    });
                })
                .catch((error) => {
                    setErrorText(error.message);
                    setMakeGlobalLoading(false);
                });
        } else {
            handleUseImage();
            setMakeGlobalLoading(false);
            setUser(auth.currentUser);
        }
    }

    const handleSignOut = () => {
        signOut(auth).then(() => {
            setUser(null);
            setUseImagePaymentTypeDialogOpen(false);
            setSignoutConfirmationDialogOpen(false);
        }).catch((error) => {
            setErrorText(error.message);
        });
    }

    const handleMakeGlobal = () => {
        if (makeGlobalLoading) return;
        setMakeGlobalLoading(true);
        handleSignInWithPopup();
    }

    const handleOnlyForThisPage = () => {
        if (onlyForThisPageLoading) return;
        setOnlyForThisPageLoading(true);
        handleSignInWithPopup();
    }

    const handleAllImagesDialogOpen = () => {
        props.onClose();
        setUseImageTypeDialogOpen(false);
        setAddImagesDialogOpen(true);
    }

    const handleAddImagesDialogClose = () => {
        props.onOpen();
        setAddImagesDialogOpen(false);
    }

    return <>
        <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                {showSettings ? <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                }}>
                    <Button variant="contained" style={{ gap: 5 }} color="primary" onClick={() => { setUseImageTypeDialogOpen(true); }}>
                        use this image <Download />
                    </Button>
                    <Button variant="outlined" onClick={handleAllImagesDialogOpen}>
                        show all images <ArrowRight />
                    </Button>
                </div> : <div></div>}
                <Button style={{ gap: 5 }} color={showSettings ? "error" : "primary"} onClick={() => {
                    setShowSettings(!showSettings);
                }}>{
                        showSettings ? "Hide settings" : "Show settings"
                    }
                    {showSettings ? <VisibilityOff /> : <Visibility />}</Button>

            </DialogTitle>
            <DialogContent>
                <Swiper
                    slidesPerView={1}
                    slidesPerGroup={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Pagination, Navigation]}
                    pagination={{ clickable: true }}
                    navigation
                >
                    <SwiperSlide>
                        <div style={{
                            aspectRatio: "16/9",
                            backgroundImage: "url(https://via.placeholder.com/1920x1080)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "100%",
                        }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div style={{
                            aspectRatio: "16/9",
                            backgroundImage: "url(https://via.placeholder.com/1920x1080)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "100%",
                        }}></div>
                    </SwiperSlide>
                </Swiper>
            </DialogContent>
        </Dialog>

        <Dialog open={useImageTypeDialogOpen} onClose={() => setUseImageTypeDialogOpen(false)} fullWidth>
            <DialogTitle style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                Use this image
                <IconButton color="error" onClick={() => setUseImageTypeDialogOpen(false)}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                }}>
                    How would you like to use this image?
                    <div style={{
                        paddingInline: 20,
                        paddingBlock: 15,
                        borderRadius: 10,
                        display: "flex",
                        gap: 10,
                        backgroundColor: "#fff1b5",
                        color: "#7a7a7a",
                    }}>
                        <Info color="warning" className="mt-1" />
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                        }}>
                            <p className="m-0 p-0">
                                <strong>Global:</strong> This image will be used in all pages.
                            </p>
                            <p className="m-0 p-0">
                                <strong>Only for this page:</strong> This image will be used only in this page.
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        gap: 10,
                    }}>
                        <Button variant="contained" onClick={handleMakeGlobal}>
                            {makeGlobalLoading ? <CircularProgress style={{ color: "#fff" }} size={24} /> : "Make Global"}
                        </Button>
                        <Button variant="contained" onClick={handleOnlyForThisPage}>
                            {onlyForThisPageLoading ? <CircularProgress style={{ color: "#fff" }} size={24} /> : "Only for this page"}
                        </Button>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>


        <Dialog open={useImagePaymentTypeDialogOpen} onClose={() => setUseImagePaymentTypeDialogOpen(false)} fullWidth>
            <DialogTitle style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <span>
                    <Payment color="primary" className="me-2" />
                    Payment type
                </span>
                <IconButton color="error" onClick={() => setUseImagePaymentTypeDialogOpen(false)}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                    fontSize: 15,
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 20,
                        borderRadius: 10,
                        paddingInline: 20,
                        paddingBlock: 15,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#969696",
                    }}>
                        {user && <div style={{
                            height: 50,
                            width: 50,
                            backgroundImage: "url('" + user.photoURL + "')",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: 100,
                            backgroundColor: "#4d4d4d",
                        }}></div>}
                        {user && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0,
                            flexGrow: 1,
                        }}>
                            <span style={{
                                color: "#1876d2",
                                fontSize: 18,
                            }}>
                                <strong>{user.displayName}</strong>
                            </span>
                            <span style={{
                                color: "#969696",
                                fontSize: 13,
                                fontWeight: "normal",
                            }}>
                                <strong>{user.email}</strong>
                            </span>
                        </div>}
                        {user && <Button variant="contained" onClick={() => {
                            setSignoutConfirmationDialogOpen(true);
                        }} style={{
                            gap: 5,
                        }}>
                            <Logout /> Sign Out
                        </Button>}
                    </div>
                    How would you like to pay for this image? Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    <CardActionArea style={{
                        height: 150,
                        width: "100%",
                        display: "flex",
                        borderRadius: 15,
                        // gradient border
                        border: "1px solid",
                        borderColor: "#ff7205",
                        backgroundImage: "linear-gradient(to right, white, #fff2d3)",
                        gap: 15,
                        paddingRight: 15,
                    }}>
                        <Player
                            autoplay={true}
                            src={SubscriptionLottieAnimation}
                            style={{
                                height: "100%",
                                aspectRatio: "1/1",
                                height: 150,
                                width: 150,
                            }}
                            loop={true}
                        >
                            <Controls visible={false} />
                        </Player>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "center",
                            gap: 5,
                        }}>
                            <label style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                color: "#ff7205",
                            }}>
                                Buy a subscription
                            </label>
                            <p className="m-0 p-0" style={{
                                color: "#969696",
                                fontSize: 15,
                            }}>
                                Get unlimited access to all images with a subscription.
                            </p>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                marginTop: 10,
                            }}>
                                <div style={{
                                    height: 40,
                                    paddingInline: 15,
                                    backgroundColor: "#ff7205",
                                    width: "fit-content",
                                    borderRadius: 100,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#fff",
                                }}>
                                    <strong>Buy now</strong>
                                </div>
                                <span style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    color: "#ff7205",
                                }}>
                                    $2.99<span style={{
                                        color: "#969696",
                                        fontSize: 13,
                                        fontWeight: 500,
                                        marginLeft: 4,
                                    }}>/mo</span>
                                </span>
                            </div>
                        </div>
                    </CardActionArea>

                    <CardActionArea style={{
                        width: "100%",
                        display: "flex",
                        borderRadius: 15,
                        // gradient border
                        border: "1px solid",
                        borderColor: "#1876d2",
                        gap: 15,
                        paddingRight: 15,
                        paddingBlock: 15,
                        justifyContent: "start",
                        paddingInline: 15,
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "center",
                            gap: 3,
                        }}>
                            <label style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: "#1876d2",
                            }}>
                                One-time purchase
                            </label>
                            <p className="m-0 p-0" style={{
                                color: "#969696",
                                fontSize: 14,
                            }}>
                                Buy this image for a one-time fee.
                            </p>
                        </div>
                        <div style={{
                            flexGrow: 1,
                        }}></div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                        }}>
                            <span style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                color: "#1876d2",
                            }}>
                                $5.99
                            </span>
                            <div style={{
                                height: 40,
                                paddingInline: 15,
                                backgroundColor: "#1876d2",
                                width: "fit-content",
                                borderRadius: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#fff",
                            }}>
                                <strong>Buy now</strong>
                            </div>
                        </div>
                    </CardActionArea>
                </DialogContentText>
            </DialogContent>
        </Dialog>

        <Snackbar
            open={errorText.length > 0}
            autoHideDuration={4000}
            onClose={() => setErrorText("")}
            message={errorText}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setErrorText("")}>
                    <Close fontSize="small" />
                </IconButton>
            }
        />

        <Dialog open={signoutConfirmationDialogOpen} onClose={() => setSignoutConfirmationDialogOpen(false)} fullWidth>
            <DialogTitle>
                Sure?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to sign out?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSignoutConfirmationDialogOpen(false)} color="inherit">
                    No
                </Button>
                <Button onClick={handleSignOut} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <AllImagesDialog open={addImagesDialogOpen} onClose={handleAddImagesDialogClose} setUseImageTypeDialogOpen={setUseImageTypeDialogOpen} />
    </>;
}