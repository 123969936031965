import { Close, Https, Star } from "@mui/icons-material";
import { CardActionArea, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Pagination, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { faker } from '@faker-js/faker';

export default function AllImagesDialog(props) {
    const [categorySelected, setCategorySelected] = useState('all')
    const [sortByPrice, setSortByPrice] = useState('none')
    const [showFavorites, setShowFavorites] = useState(false)

    useEffect(() => {
        if (props.open) {
            setCategorySelected('all')
            setSortByPrice('none')
            setShowFavorites(false)
        }
    }, [props.open])

    return <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
        <DialogTitle style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                display: 'flex',
                paddingLeft: 10,
            }}>
                All Images
                <IconButton color="error" onClick={props.onClose}>
                    <Close />
                </IconButton>
            </div>
            <div style={{ paddingInline: 10 }} className="row m-0 mt-2 align-items-center justify-content-between d-flex">
                <div className="row m-0 p-0 align-items-center d-flex" style={{ width: "fit-content" }}>
                    <FormControl style={{ width: 200 }}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={categorySelected}
                            label="Category"
                            onChange={(e) => setCategorySelected(e.target.value)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="nature">Nature</MenuItem>
                            <MenuItem value="people">People</MenuItem>
                            <MenuItem value="tech">Tech</MenuItem>
                            <MenuItem value="animals">Animals</MenuItem>
                            <MenuItem value="buildings">Buildings</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl style={{ width: 200, marginLeft: 20 }}>
                        <InputLabel>Sort by price</InputLabel>
                        <Select
                            value={sortByPrice}
                            label="Sort by price"
                            onChange={(e) => setSortByPrice(e.target.value)}
                        >
                            <MenuItem value="none">Not selected</MenuItem>
                            <MenuItem value="high-to-low">High to Low</MenuItem>
                            <MenuItem value="low-to-high">Low to High</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <FormControlLabel
                    style={{ width: "fit-content" }}
                    label="Show favorites"
                    checked={showFavorites}
                    onChange={(e) => setShowFavorites(e.target.checked)}
                    control={
                        <Checkbox color="primary" />
                    }
                />
            </div>
        </DialogTitle>
        <DialogContent className="pb-0">
            <div className="row m-0 p-0">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6].map((item, index) => {
                    return <ImageCard key={index} setUseImageTypeDialogOpen={props.setUseImageTypeDialogOpen}></ImageCard>
                })}
            </div>
        </DialogContent>
        <DialogActions>
            <Pagination className="py-2" count={10} color="primary" />
        </DialogActions>
    </Dialog>
}

function ImageCard(props) {
    const [isFavorite, setIsFavorite] = useState(false);
    return <div className="col-4" style={{
        aspectRatio: "1/1",
        position: "relative",
    }}>
        <CardActionArea style={{
            margin: 10,
            left: 0,
            height: "calc(100% - 20px)",
            width: "calc(100% - 20px)",
            backgroundColor: "lightgray",
            backgroundImage: `url(${faker.image.imageUrl()})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            borderRadius: 23,
            overflow: "hidden",
        }} onClick={props.setUseImageTypeDialogOpen}></CardActionArea>
        <IconButton style={{
            position: "absolute",
            top: 15,
            left: 15,
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
        }} onClick={() => setIsFavorite(!isFavorite)} color={isFavorite ? "warning" : "default"}>
            <Star />
        </IconButton>
        <div style={{
            position: "absolute",
            top: 15,
            right: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            width: 40,
            borderRadius: "50%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            pointerEvents: "none",
        }}>
            <Https />
        </div>

        <div style={{
            position: "absolute",
            bottom: 18,
            right: 18,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 100,
            paddingInline: 8,
            paddingBlock: 4,
            fontSize: 14,
            fontWeight: 600,
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            pointerEvents: "none",
        }}>
            $1.00
        </div>
    </div>;
}