import { Button } from "@mui/material";
import { useState } from "react";
import ImageViewerDialog from "../components/image-viewer-dialog";

export default function HomePage() {
    const [popupButtonHovered, setPopupButtonHovered] = useState(false);
    const [imageViewerDialogOpen, setImageViewerDialogOpen] = useState(false);

    return <>
        <ImageViewerDialog open={imageViewerDialogOpen} onClose={() => setImageViewerDialogOpen(false)} onOpen={() => { setImageViewerDialogOpen(true); }} />
        <Button
            onMouseEnter={() => setPopupButtonHovered(true)}
            onMouseLeave={() => setPopupButtonHovered(false)}
            variant="contained"
            style={{
                opacity: popupButtonHovered ? 1 : 0.5,
                transition: "opacity 0.3s",
                position: "fixed",
                top: 10,
                right: 10,
            }}
            onClick={() => setImageViewerDialogOpen(true)}
        >Open Dialog</Button>
    </>;
}